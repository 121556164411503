<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="card p-2">
        <div>
        <!-- <h3 class="font-weight-bolder text-center">
          รายการล็อตเตอรี่ที่ถูกรางวัล
        </h3> -->
        </div>
        <div class="row">
          <div class="col-8">
          <!-- <div>
            <label for="example-datepicker">เลือกรอบวันที่</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="round"
              class="mb-1"
            />
          </div> -->
          <!-- <div>
            <label for="lotto">กรอกเลขล็อตเตอรี่</label>
            <input
              id="lotto"
              v-model="lotto"
              type="text"
              class="form-control"
              placeholder="กรอกเลขล็อตเตอรี่"
            >
          </div> -->

          <!-- <div class="mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="onSubmit()"
            >
              ค้นหาข้อมูล
            </button>
          </div> -->
          </div>
        </div>
        <div class="card-body">
          <div>
            <h2 class="text-dark font-medium-3">
              <i class="fa fa-search" /> ค้นหา
            </h2>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="username">มาจาก</label>
                <b-form-select
                  v-model="buytype"
                  :options="buytype_op"
                  placeholder="มาจาก"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="username">Keyword</label>
                <input
                  id="username"
                  v-model="search_val"
                  type="text"
                  class="form-control"
                  placeholder="กรอกข้อมูลที่ต้องการค้นหา"
                >
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="example-datepicker">เลือกตามงวด</label>
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="round"
                  class="mb-1"
                />
              </div>
            </div>
            <!-- <div class="col-md-2">
              <div class="form-group">
                <label for="dateStart">วันที่เริ่มต้น</label>
                <flat-pickr
                  v-model="dateStart"
                  class="form-control"
                  placeholder="เลือกเวลาเริ่มต้น"
                />
              </div>
            </div> -->
            <!-- <div class="col-md-2">
              <div class="form-group">
                <label for="dateEnd">วันที่สิ้นสุด</label>
                <flat-pickr
                  v-model="dateEnd"
                  class="form-control"
                  placeholder="เลือกเวลาสิ้นสุด"
                />
              </div>
            </div> -->
            <div class="col-md-1 mt-2">
              <button
                class="btn btn-gradient-dark"
                @click="onSubmit()"
              >
                <feather-icon icon="SearchIcon" />
                ค้นหา
              </button>
            </div>
          <!-- <div class="col-md-1 mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="ExportExcel(round)"
            >
              Export
            </button>
          </div> -->
          </div>
        </div>
        <p
          style="color: red;"
          class="ml-2"
        >
          * ข้อมูลการ์ดซื้อด้วยด้วยเครดิต ซื้อด้วยเพชรและหมุนกงล้อ จะคำนวณตามงวดถ้าไม่เลือกงวดจะนำข้อมูลที่ถูกรางวัลทั้งหมดมาคำนวณ
        </p>

      </div>
      <b-row class="match-height h-100">
        <b-col md="4">
          <div class="card h-100 shadow-sm">
            <b-card-body class="pb-2 text-center">
              <h3 class="mb-2 text-primary">
                ซื้อด้วยเครดิต
              </h3>
              <div class="mt-1">
                <h4 class="mb-0 font-weight-bold">
                  <span>จำนวน</span>
                  {{
                    sumdata.reduce((sum, item) => {
                      return item.buytype === 'credit' ? sum + item.count : sum;
                    }, 0)
                  }} ใบ
                </h4>
                <h5 class="mt-2">
                  <span style="font-size: 0.95rem">คิดเป็นเงิน</span>
                  {{
                    sumdata.reduce((sum, item) => {
                      return item.buytype === 'credit' && item.currency === 'THB'
                        ? sum + Number(item.reward_pay)
                        : sum;
                    }, 0).toLocaleString()
                  }} THB |  {{
                    sumdata.reduce((sum, item) => {
                      return item.buytype === 'credit' && item.currency === 'KRW'
                        ? sum + Number(item.reward_pay)
                        : sum;
                    }, 0).toLocaleString()
                  }} KRW
                </h5>
              </div>
            </b-card-body>
          </div>
        </b-col>
        <b-col md="4">
          <div class="card h-100 shadow-sm">
            <div class="card h-100 shadow-sm">
              <b-card-body class="pb-2 text-center">
                <h3 class="mb-2 text-success">
                  ซื้อด้วยเพชร
                </h3>
                <div class="mt-1">
                  <h4 class="mb-0 font-weight-bold">
                    <span>จำนวน</span> {{
                      sumdata.reduce((sum, item) => {
                        return item.buytype === 'point' & item.from === 'ซื้อสลาก' ? sum + item.count : sum;
                      }, 0)
                    }} ใบ
                  </h4>
                  <h5 class="mt-2">
                    <span style="font-size: 0.95rem">คิดเป็นเงิน</span> {{
                      sumdata.reduce((sum, item) => {
                        return item.buytype === 'point' & item.from === 'ซื้อสลาก' & item.currency === 'THB'
                          ? sum + Number(item.reward_pay)
                          : sum;
                      }, 0).toLocaleString()
                    }}  THB | {{
                      sumdata.reduce((sum, item) => {
                        return item.buytype === 'point' & item.from === 'ซื้อสลาก' & item.currency === 'KRW'
                          ? sum + Number(item.reward_pay)
                          : sum;
                      }, 0).toLocaleString()
                    }} KRW
                  </h5>
                </div>
              </b-card-body>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="card h-100 shadow-sm">
            <div class="card h-100 shadow-sm">
              <b-card-body class="pb-2 text-center">
                <h3 class="mb-2 text-warning">
                  หมุนกงล้อ
                </h3>
                <div class="mt-1">
                  <h4 class="mb-0 font-weight-bold">
                    <span>จำนวน</span> {{
                      sumdata.reduce((sum, item) => {
                        return item.buytype === 'point' & item.from === 'หมุนกงล้อ' ? sum + item.count : sum;
                      }, 0)
                    }} ใบ
                  </h4>
                  <h5 class="mt-2">
                    <span style="font-size: 0.95rem">คิดเป็นเงิน</span> {{
                      sumdata.reduce((sum, item) => {
                        return item.buytype === 'point' & item.from === 'หมุนกงล้อ' & item.currency === 'THB'
                          ? sum + Number(item.reward_pay)
                          : sum;
                      }, 0).toLocaleString()
                    }}  THB | {{
                      sumdata.reduce((sum, item) => {
                        return item.buytype === 'point' & item.from === 'หมุนกงล้อ' & item.currency === 'KRW'
                          ? sum + Number(item.reward_pay)
                          : sum;
                      }, 0).toLocaleString()
                    }}  KRW
                  </h5>
                </div>
              </b-card-body>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="card">
        <!-- <div class="d-flex mt-2 ml-2">
        <b-button
          variant="gradient-success"
          class="rounded mr-1"
        >
          พร้อมจำหน่าย
        </b-button>

        <b-button
          variant="gradient-warning"
          class="rounded"
        >
          จำหน่ายแล้ว
        </b-button>
      </div> -->
        <b-table
          hover
          show-empty
          responsive
          :items="items"
          :fields="fields"
          class="mt-1"
        >
          <template #cell(id)="data">
            <router-link :to="{ name: '' }">
              {{ data.item.id }}
            </router-link>
          </template>

          <template #cell(depfirst_amount)="data">
            {{ data.item.depfirst_amount ? data.item.depfirst_amount : "0.00" }}
          </template>
          <template #cell(created_at)="data">
            ( {{ Time(data.item.created_at) }} ) {{ data.item.created_at }}
          </template>

          <template #empty="scope">
            <div class="p-2 text-center">
              <img
                src="/box.png"
                alt="empty"
                height="40px"
              > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
            </div>
          </template>

          <template #cell(result_win_type)="data">

            <span
              v-if="data.item.result_win_type == 'num6'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลที่ 1</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num5'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลข 5 ตัว </span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num4'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลข 4 ตัว</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num3_front'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลขหน้า 3 ตัว</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num3_back'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลขท้าย 3 ตัว</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num3t_front'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลขหน้า 3 ตัว โต๊ต</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num3t_back'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลขท้าย 3 ตัว โต๊ต</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num2'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลขท้าย 2 ตัว</span>
            </span>
            <span
              v-if="data.item.result_win_type == 'num1'"
              class="badge badge-success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>ถูกรางวัลเลขท้าย 1 ตัว</span>
            </span>
          </template>

          <template #cell(buytype)="data">
            {{ data.item.buytype === 'credit' ? 'เครดิต' : 'เพชร' }}
          </template>
          <template #cell(rewardpay)="data">
            {{ data.item.reward_pay ? Commas(Number(data.item.reward_pay).toFixed(2)) : "0.00" }} {{ data.item.currency }}
          </template>
          <template #cell(action)>
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                class="mr-1"
                size="sm"
              >
                <i class="fa fa-edit" />
              </b-button>
            <!-- <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button> -->
            </div>
          </template>
        </b-table>
        <b-card-body
          class="d-flex justify-content-between flex-wrap pt-0 pl-1 pb-1"
        >
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="getList()"
            />
          </b-form-group>

          <!-- pagination -->
          <div class="pr-1 pt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getList()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BFormDatepicker, BTable, BCardBody, BPagination, BFormGroup, BFormSelect, BButton,
  BOverlay,
  BIconController,
  // BCardText,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  name: 'Reward',
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BTable,
    BCardBody,
    // BCardText,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      lotto: '',
      list: [],
      round: null,
      fields: [
        { key: 'from', label: 'มาจาก' },
        { key: 'member_buy', label: 'ยูสเซอร์เนม' },
        { key: 'member_tel', label: 'เบอร์โทร' },
        { key: 'buytype', label: 'ซื้อด้วย' },
        { key: 'lottonumber', label: 'เลขลสาก' },
        { key: 'count', label: 'จำนวน (ใบ)' },
        { key: 'reward_status', label: 'สถานะ' },
        { key: 'rewardpay', label: 'เงินรางวัล' },
        { key: 'roundth', label: 'งวดประจำวันที่' },
        // { key: 'agent_buy', label: 'ตัวเทน' },
        { key: 'member_buy', label: 'ชื่อลูกค้า' },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      show: false,
      perPage: 20,
      pageOptions: [20, 50, 100, 200, 500],
      sumdata: null,
      buytype: 'ทั้งหมด',
      buytype_op: ['ทั้งหมด', 'ซื้อด้วยเครดิต', 'ซื้อด้วยเพชร', 'หมุนกงล้อ', 'กล่องสุ่ม'],
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      search_val: null,
    }
  },
  mounted() {
    this.getList()
    this.getSumReward()
  },
  methods: {
    onSubmit() {
      this.getList()
      this.getSumReward()
    },
    getList() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        round: this.round,
        buytype: this.buytype,
        search_val: this.search_val,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/lottery/GetRewardList', params)
        .then(response => {
          this.show = false
          this.items = response.data.lottery
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    getSumReward() {
      const params = {
        round: this.round,
      }
      this.$http
        .post('/lottery/GetSumReward', params)
        .then(response => {
          this.sumdata = response.data
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.table {
    border-spacing: 0 15px;
    border-collapse: separate;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    scrollbar-width: none;
}
table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
th {
    text-align: inherit;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.table tbody tr {
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
    border-radius: 5px 0 0 5px;
}
.table tbody tr td {
    background: #fff;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
</style>
